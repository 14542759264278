import React from 'react';

const component = props => (
  <div style={{height: 80, backgroundColor: "#162A56", position: "relative"}}>
    <div style={{paddingLeft: 20, lineHeight: 5, color: "white"}}>
      © 2021 Dayna J. Pineda Attorney At Law. All rights reserved.
    </div>
  </div>
);

export default component;
