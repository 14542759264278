import React, { Component } from 'react';

import './ImageBackgroundBox.css';

class ImageBackgroundBox extends Component {
  render() {
    return (
      <div style={{height: this.props.height}}>
        <img src={this.props.imagePath} style={{height: this.props.height}}alt="alt"/>
      </div>
    );
  }
}

export default ImageBackgroundBox;
