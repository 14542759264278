import React, { Component } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Topbar from '../components/Topbar/Topbar';
import AreasOfPracticeInfo from '../components/AreasOfPracticeInfo/AreasOfPracticeInfo';
import BottomSection from '../components/BottomSection/BottomSection.js';

class AreasOfPractice extends Component {
    componentDidMount(){
      document.title = "Areas of Practice | Dayna J. Pineda Law"
    }
    render() {
        return (
          <div>
          <Topbar />
          <Navbar activeTab="areas-of-practice"/>
          <AreasOfPracticeInfo backgroundColor="#203D67"/>
          <BottomSection />
          </div>
        );
    }
}

export default AreasOfPractice;
