import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import ImageBackgroundBox from '../containers/ImageBackgroundBox.js';

import Line from '../parts/line.js';

const Title = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 28px;
`;

const AboutContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ isDesktop }) => isDesktop ? 'row' : 'column'};
  align-items: flex-start;
`;

const BackgroundImage = styled.div`
  position: fixed;
  background-color: grey;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }
`;

const Description = styled.div`
  padding-top: 20px;
  margin-left: ${({ isDesktop }) => isDesktop ? '40px' : '0px'};
`;

const Pane = styled.div`
  background-color: white;
  position: relative;
`;

const OverlayText = styled.div`
`;

const FixedBackgroundImg = styled.div`
  height: window.innerHeight;
  position: absolute;
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, .6);
  width: 100%;
`;

const BackgroundImg = styled.div`
  height: 500px;
  background: linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5)),
                  url("../resources/sandiego5.jpeg");
`;

const Outer = styled.div`
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  background-color: white;
`;

function ValueInfoComponent() {
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth > 1000)
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Outer>
      <div style={{height: "2px"}} />
      <Title>Our Values</Title>
      <Line height="3px" backgroundColor="#FC5B00" />
      <br />
      The three pillars of our firm are <b>EXPERIENCE</b>, <b>INTEGRITY</b> and <b>VALUE</b>. We believe these pillars
      differentiate our legal services from those offered by others.
      <br /><br />
      In order to provide effective counsel to business clients, <b>EXPERIENCE</b> needs to encompass both
  in depth knowledge of the law and practical business expertise. Having such experience gives
  us understanding of the technical world as an insider and allows us to develop effective legal
  strategies custom-tailored to the unique challenges businesses face as they grow.
  <br /><br />
  <b>INTEGRITY</b> is integral to our practice. At our firm, you can count on honest, straight-forward,
  practical advice and efficient legal matter management. Unfortunately, almost all law firms
  now have very unrealistic billing quotas for the attorneys they employ. This causes a very real
  tension between what is in a client’s best interest and the billable time an individual attorney is
  required “to produce” for the firm who is employing that attorney. We are free from such
  constraints and aim to complete your legal work as efficiently as possible.
  <br /><br />
  Finally, with regard to <b>VALUE</b>, our overhead costs are low, so we are pleased to pass along
  these savings to our clients. Our hourly rate is currently less than half of what it typically would
  be at any large law firm. Most clients will appreciate the cost savings of retaining our firm
  immediately.
    </Outer>
  );
  return (
    <>
      <div style={{height: "2px"}} />
      <FixedBackgroundImg>
        <img style={{height: "100%"}} src={require("../resources/sandiego5.jpeg")} />
        <Overlay>
        </Overlay>
      </FixedBackgroundImg>
      <Overlay>
      <OverlayText>
    In order to provide effective counsel to business clients, <b>EXPERIENCE</b> needs to encompass both
in depth knowledge of the law and practical business expertise. Having such experience gives
us understanding of the technical world as an insider and allows us to develop effective legal
strategies custom-tailored to the unique challenges businesses face as they grow.
<br /><br />
<b>INTEGRITY</b> is integral to our practice. At our firm, you can count on honest, straight-forward,
practical advice and efficient legal matter management. Unfortunately, almost all law firms
now have very unrealistic billing quotas for the attorneys they employ. This causes a very real
tension between what is in a client’s best interest and the billable time an individual attorney is
required “to produce” for the firm who is employing that attorney. We are free from such
constraints and aim to complete your legal work as efficiently as possible.
<br /><br />
Finally, with regard to <b>VALUE</b>, our overhead costs are low, so we are pleased to pass along
these savings to our clients. Our hourly rate is currently less than half of what it typically would
be at any large law firm. Most clients will appreciate the cost savings of retaining our firm
immediately.
      </OverlayText>
      </Overlay>
      <br />
      <br />
      <br />
      <br />
      <Line height="3px" backgroundColor="#FC5B00" />
      <br />
      The three pillars of our firm are <b>EXPERIENCE</b>, <b>INTEGRITY</b> and <b>VALUE</b>. We believe these pillars
      differentiate our legal services from those offered by others.
      <br /><br />
      In order to provide effective counsel to business clients, <b>EXPERIENCE</b> needs to encompass both
  in depth knowledge of the law and practical business expertise. Having such experience gives
  us understanding of the technical world as an insider and allows us to develop effective legal
  strategies custom-tailored to the unique challenges businesses face as they grow.
  <br /><br />
  <b>INTEGRITY</b> is integral to our practice. At our firm, you can count on honest, straight-forward,
  practical advice and efficient legal matter management. Unfortunately, almost all law firms
  now have very unrealistic billing quotas for the attorneys they employ. This causes a very real
  tension between what is in a client’s best interest and the billable time an individual attorney is
  required “to produce” for the firm who is employing that attorney. We are free from such
  constraints and aim to complete your legal work as efficiently as possible.
  <br /><br />
  Finally, with regard to <b>VALUE</b>, our overhead costs are low, so we are pleased to pass along
  these savings to our clients. Our hourly rate is currently less than half of what it typically would
  be at any large law firm. Most clients will appreciate the cost savings of retaining our firm
  immediately.
    </>
  );
}

export default ValueInfoComponent;
