import React, { Component } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Topbar from '../components/Topbar/Topbar';
import HomeInfo from '../components/HomeInfo/HomeInfo';
import BottomSection from '../components/BottomSection/BottomSection.js';

class Home extends Component {
  componentDidMount(){
    document.title = "Home | Dayna J. Pineda Law"
  }
  render() {
    return (
      <div className="App">
      <Topbar />
      <Navbar activeTab="home"/>
      <HomeInfo />
      <BottomSection />
      </div>
    );
  }
}

export default Home;
