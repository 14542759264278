import React, { Component } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Topbar from '../components/Topbar/Topbar';
import AboutInfo from '../components/AboutInfo/AboutInfo';
import BottomSection from '../components/BottomSection/BottomSection.js';


class About extends Component {
  componentDidMount(){
    document.title = "About | Dayna J. Pineda Law"
  }
  render() {
      return (
        <div className="App">
        <Topbar />
        <Navbar activeTab="about"/>
        <AboutInfo />
        <BottomSection />
        </div>
      );
  }
}

export default About;
