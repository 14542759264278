import React, { Component } from 'react';
import Home from './pages/Home.js'
import About from './pages/About.js'
import Values from './pages/Values.js'
import AreasOfPractice from './pages/AreasOfPractice.js'
import Contact from './pages/Contact.js'
import Payment from './pages/Payment.js'
import Specialty from './pages/Specialty.jsx'
import Page404 from './pages/Page404.jsx'
import ContactThankYou from './pages/ContactThankYou.jsx'
import {
  BrowserRouter as Router,
  Route, Switch
} from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/values" component={Values} />
          <Route exact path="/areas-of-practice" component={AreasOfPractice} />
            <Route path="/areas-of-practice/:id" component={Specialty} />
          <Route path="/contact" component={Contact} />
          <Route path="/payment" component={Payment} />
          <Route path="/contact-thank-you" component={ContactThankYou} />
          <Route component={Page404} />
        </Switch>
      </Router>
    );
  }
}

export default App;
