import React, { Component } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Topbar from '../components/Topbar/Topbar';
import BottomSection from '../components/BottomSection/BottomSection.js';
import SpecialtyInfo from '../components/SpecialtyInfo.jsx';

import * as Constants from '../resources/written-content/content.js';

class Specialty extends Component {
    render() {
      var title = ""
      var desc = ""
      var img = ""
      let id = this.props.match.params.id
      let aops = Constants.AREAS_OF_PRACTICE
      for (var i = 0; i < aops.length; ++i) {
        console.log(aops[i].id)
        if (aops[i].id === id) {
          title = aops[i].title
          desc = aops[i].desc
          img = aops[i].img
          break
        }
      }
      return (
        <div>
          <Topbar />
          <Navbar activeTab="areas-of-practice"/>
          <SpecialtyInfo title={title} description={desc} img={img} id={id}/>
          <BottomSection />
        </div>
      );
    }
}

export default Specialty;
