import React, { Component } from 'react';

import { Link } from "react-router-dom";

import * as Constants from '../resources/written-content/content.js';
import '../App.css';
import BusinessImg from '../resources/images/areas-of-expertise/business.jpeg'
import IntelPropImg from '../resources/images/areas-of-expertise/intellectual-property.jpg'
import VirtualGenCounsel from '../resources/images/areas-of-expertise/virtual-general-counsel.jpg'

function getImagePath(id) {
  if (id === "business") {
    return BusinessImg
  } else if (id === "intellectual-property") {
    return IntelPropImg
  }
  return VirtualGenCounsel
}

class AreasOfPracticePanes extends Component {
  render() {
    return (
      <ol style={{marginTop: "0px", paddingLeft: "10px", paddingRight: "20px",
      position: "sticky", display: "flex", flexWrap: "wrap",
       justifyContent: "space-around", listStyle: "none"}}>
         {Constants.AREAS_OF_PRACTICE.map(aoe => (
           <Link to={`/areas-of-practice/${aoe.id}`} style={{textDecoration: "none", color: "black"}}>
             <li key={aoe.id}>
              <div style={{height: "360px", width: "320px", backgroundColor: "rgba(255, 255, 255, 0.8)", marginBottom: "40px",
                marginLeft: "10px", marginRight: "10px", position: "relative", boxShadow: "0px 2px 9px 2px rgba(0,0,0,0.75)"}}>
                <img src={getImagePath(aoe.id)} style={{height: "80%"}}alt="alt"/>
                <h2 style={{textAlign: "center", verticalAlign: "middle"}}>{aoe.name}</h2>
              </div>
             </li>
           </Link>
         ))}
      </ol>
    );
  }
}

export default AreasOfPracticePanes;
