import React, { Component } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Topbar from '../components/Topbar/Topbar';
import AboutInfo from '../components/AboutInfo/AboutInfo';

class Payment extends Component {
  componentDidMount(){
    document.title = "Payment | Dayna J. Pineda Law"
  }
  render() {
      return (
        <div>
        <Topbar />
        <Navbar activeTab="payment"/>
        <AboutInfo />
        </div>
      );
  }
}

export default Payment;
