import React, { Component } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Topbar from '../components/Topbar/Topbar';
import ValueInfo from '../components/ValueInfo.jsx';
import BottomSection from '../components/BottomSection/BottomSection.js';

class Values extends Component {
  componentDidMount(){
    document.title = "Values | Dayna J. Pineda Law"
  }
  render() {
    return (
      <>
        <Topbar />
        <Navbar activeTab="values"/>
        <ValueInfo />
        <BottomSection />
      </>
    );
  }
}

export default Values;
