import React, { useState, useEffect } from 'react';
import styled from "styled-components";

import './Topbar.css';

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #162A56;
  opacity: ${({ open }) => open ? '90%' : '90%'};
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
  height: 100vh;
  width: 100%;
  text-align: right;
  padding: 0;
  position: ${({ open }) => open ? 'absolute' : 'fixed'};
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 20;

  a {
    font-size: 22px;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #FFFFFF;
    text-decoration: none;
    transition: color 0.3s linear;
    text-align: center;

    &:hover {
      color: #FC5B00;
    }
  }
`

const Menu = ({ open }) => {
  return (
    <StyledMenu open={open}>
      <a href="../">Home</a>
      <a href="../about">About</a>
      <a href="../values">Values</a>
      <a href="../areas-of-practice">Areas of Practice</a>
      <a href="../contact">Contact</a>
    </StyledMenu>
  );
}

const StyledBurger = styled.button`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 30;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: #EFFFFA;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

const Burger = ({ isDesktop, open, setOpen }) => {
  if (isDesktop) {
    return null
  }
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

const TopBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 100%; */
  height: 100px;
  position: relative;
`;
const Clickable = styled.a`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
`;
const Title1 = styled.div`
  padding-left: 40px;
  color: white;
  font-size: 36px;
`;
const Title2 = styled.div`
  padding-left: 40px;
  color: #CECECE;
  font-size: 28px;
`;
const Hamburger = styled.div`
  right: 30px;
  position: absolute;
`;

function TopBarComponent() {
  const [open, setOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth > 1000)
      setOpen(false)
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <TopBar>
      <div style={{height: "20px"}} />
      <Clickable href="/">
        <Title1>Dayna J. Pineda <br /></Title1>
        <Title2>Attorney at Law</Title2>
      </Clickable>
      <Hamburger>
        <Burger isDesktop={isDesktop} open={open} setOpen={setOpen} />
      </Hamburger>
      <Menu open={open} setOpen={setOpen} />
    </TopBar>
  );
}

export default TopBarComponent;
