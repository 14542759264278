import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import Line from '../../parts/line.js';

import './AboutInfo.css';

import ProfileImg from "../../resources/images/daynapineda.jpg"

const Outer = styled.div`
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  background-color: white;
`;

const Title = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 28px;
`;

const AboutContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ isDesktop }) => isDesktop ? 'row' : 'column'};
  align-items: flex-start;
`;

const Image = styled.div`
  margin: ${({ isDesktop }) => isDesktop ? '0 auto' : '0 auto'};
  height: 460px;
  min-width: 300px;
  max-width: 300px;
  background-color: grey;
  img {
    height: 100%;
    object-fit: cover;
  }
`;

const Description = styled.div`
  padding-top: 20px;
  margin-left: ${({ isDesktop }) => isDesktop ? '40px' : '0px'};
`;

function AboutInfoComponent() {
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth > 1000)
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Outer>
      <Title>Dayna J. Pineda</Title>
      <Line height="3px" backgroundColor="#FC5B00" />
      <br />
      <AboutContent isDesktop={isDesktop}>
        <Image><img src={ProfileImg} /></Image>
        <Description isDesktop={isDesktop}>
          Dayna J Pineda is a leading Southern California business attorney. Throughout her
          many years of experience, she has represented clients of all sizes and industry
          sectors ranging from small technology start-ups to Fortune 500 companies.
          <br />
          <br />
          Ms. Pineda graduated from the University of San Diego School of Law where she
          obtained her Juris Doctor degree. Following law school, Ms. Pineda was employed
          for many years by leading, worldwide law firm DLA Piper (formerly Gray Cary Ware
          &amp; Freidenrich, LLP) where she advised clients in two main practice areas, namely
          Corporate &amp; Securities and Intellectual Property and Technology law. Thereafter,
          she formed her own private practice and has served as general counsel and advisor
          for multiple clients throughout the region.
          <br />
          <br />
          Ms. Pineda has extensive experience in the biopharmaceutical industry. She
          graduated cum laude from the University of California, Santa Barbara with a
          bachelor’s degree in biochemistry. Thereafter, she held a variety of technical and
          regulatory positions of increasing responsibility at AMGEN Inc and Alliance
          Pharmaceutical Corp giving her real-world industry experience and perspective in
          areas such as research, manufacturing, clinical and regulatory affairs as well as all
          manner of legal matters affecting publicly-traded companies. As such, Ms. Pineda
          recognizes the unique needs and challenges faced by small businesses and
          technology clients so her viewpoint, advice and legal services are practically tailored
          to meet individual client goals in a timely and cost-effective manner.
          <br />
          <br />
          Ms. Pineda is a member of the State Bar of California and admitted to practice law in
          the United States District Court for the Southern District of California. She is a
          member of the Business law section of the California Lawyer’s Association, past-member
           of the BIOCOM Legislative Committee, and current member of the Rancho
          Santa Fe Women’s Fund Grant Committee where she is actively involved in funding
          deserving local charities throughout San Diego county. For the past six years, Ms.
          Pineda has also volunteered her time to the community as a high school mock trial
          coach for La Jolla Country Day School where she takes particular pride in serving as
          a positive professional role model, encouraging students to expand their critical
          reasoning and speaking skills, and consider law as a potential career. Ms. Pineda
          enjoys an active, outdoor lifestyle with hobbies that include swimming, skiing and
          traveling.
        </Description>
      </AboutContent>
    </Outer>
  );
}

export default AboutInfoComponent;
