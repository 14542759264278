import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import './AreasOfPracticeInfo.css';

import AreasOfPracticePanes from '../../containers/AreasOfPracticePanes.js';

const Title = styled.div`
  position: absolute;
  width: ${({ isDesktop }) => isDesktop ? '500px' : '100%'};
  top: 40px;
  left: ${({ isDesktop }) => isDesktop ? '80px' : '0px'};
  text-align: ${({ isDesktop }) => isDesktop ? 'left' : 'center'};
  color: white;
`;

function AreasOfPracticeInfoComponent(props) {
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth > 1000)
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div style={{position: "relative", backgroundColor: props.backgroundColor}}>
      <div style={{paddingTop: "160px", paddingBottom: "160px"}}>
        <AreasOfPracticePanes />
      </div>
      <Title isDesktop={isDesktop}><h1>Areas of Practice</h1></Title>
    </div>
  );
}

export default AreasOfPracticeInfoComponent;
