import React, { Component } from 'react';
import styled from "styled-components";

import Navbar from '../components/Navbar/Navbar';
import Topbar from '../components/Topbar/Topbar';
import BottomSection from '../components/BottomSection/BottomSection.js';

const Message404 = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  height: 200px;
  background-color: white;
  flex-direction: column;
  width: 100%;
`;

class Page404 extends Component {
    componentDidMount(){
      document.title = "Page Not Found | Dayna J. Pineda Law"
    }
    render() {
      return (
        <>
          <Topbar />
          <Navbar />
          <Message404>
            Whoops!
            Page Not Found
          </Message404>
          <BottomSection />
        </>
      );
    }
}

export default Page404;
