import React from 'react';
import styled from 'styled-components';

const Services = styled.div`
  color: black;
  font-style: italic;
  text-align: center;
`;

const Text = styled.div`
  color: #686868;
`;

const BulletList = styled.ul`
  li {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

function AreasOfPracticeWrittenContent(props) {
  switch (props.id) {
    case 'business':
      return (
        <Text>
          <br />
          <br />
          <Services>Our Services</Services>
          <br />
          <br />
        Our firm assists clients with business transactional services in areas such as:
          <br />
          <BulletList>
            <li>Business entity selection, general corporate counseling and governance</li>
            <li>Contract review, drafting and negotiation</li>
            <li>Non-disclosure agreements</li>
            <li>Consulting and employment agreements</li>
            <li>Review of business plans and pitch decks</li>
            <li>Corporate Secretary services to Board of Directors</li>
            <li>Venture capital and other financing transactions</li>
          </BulletList>
          To learn more, please contact our office for an introductory consultation. We look
forward to assisting you.
        </Text>
      );
    case 'intellectual-property':
      return (
        <Text>
          <br />
          <br />
          <Services>Our Services</Services>
          <br />
          <br />
        Our firm can assist you with all manner of contracts and transactional
        matters involving your technology business. The following are
        representative examples:
          <br />
          <BulletList>
            <li>Non-disclosure agreements</li>
            <li>Consulting and employment agreements</li>
            <li>Intellectual property assignment and license agreements</li>
            <li>Research and development agreements</li>
            <li>Joint venture and strategic alliance agreements</li>
            <li>Manufacturing and supply agreements</li>
            <li>Product purchase agreements</li>
            <li>Warranties and end-user agreements</li>
            <li>Distributor and sales agent agreements</li>
            <li>Informed consent, clinical research and contract research organization agreements</li>
          </BulletList>
          To learn more, please contact our office for an introductory consultation. We look
forward to assisting you.
        </Text>
      );
    case 'virtual-general-counsel':
      return (
        <Text>
          <br />
          <br />
          <Services>Our Services</Services>
          <br />
          <br />
          A virtual general counsel offers many advantages for small to medium-sized,
  growing businesses including:
          <br />
          <BulletList>
            <li>A knowledgeable, experienced lawyer who knows your business and goals as
well as being responsive to your needs by providing timely strategic advice
as a part of your management team</li>
            <li>A legal point person who can oversee your organization’s needs to ensure
important legal considerations are addressed and appropriately coordinated
among other outside attorney specialists providing services to the business</li>
            <li>Review of legal bills to ensure that all provider estimates are realistic and
bills are fair so as to provide value to the organization</li>
            <li>Oversight and coordination among contracts, strategic alliances and other
transactional matters to ensure synchronization so as to avoid conflicts and
costly legal errors that may threaten the viability of the business</li>
            <li>During negotiations, the enhanced credibility, competence and stability that
accompanies having an in-house general counsel without the high cost of
employing one full-time</li>
          </BulletList>
          To learn more, please contact our office for an introductory consultation. We look
forward to assisting you.
        </Text>
      );
    default:
  }
  return (
    <>
    </>
  );
}

export default AreasOfPracticeWrittenContent;
