import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import AreaOfPracticeContent from '../written-content/areas-of-practice.jsx';
import Line from '../parts/line.js';

import BusinessImg from '../resources/images/areas-of-expertise/business.jpeg'
import IntelPropImg from '../resources/images/areas-of-expertise/intellectual-property.jpg'
import VirtualGenCounselImg from '../resources/images/areas-of-expertise/virtual-general-counsel.jpg'

const Outer = styled.div`
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  background-color: white;
`;

const Title = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 28px;
`;

const SpecialtyContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ isDesktop }) => isDesktop ? 'row' : 'column'};
  align-items: flex-start;
`;

const Image = styled.div`
  margin: ${({ isDesktop }) => isDesktop ? '0 auto' : '0 auto'};
  height: 460px;
  min-width: 300px;
  max-width: 300px;
  background-color: grey;
  img {
    height: 100%;
    object-fit: cover;
  }
`;

const Description = styled.div`
  color: #686868;
  font-size: 20px;
  margin-top: ${({ isDesktop }) => isDesktop ? '0px' : '40px'};
  margin-left: ${({ isDesktop }) => isDesktop ? '40px' : '0px'};
`;

function SpecialtyInfoComponent(props) {
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth > 1000)
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  var imgPath
  if (props.img == "business.jpeg") {
    imgPath = BusinessImg
  } else if (props.img == "business.jpeg") {
    imgPath = IntelPropImg
  } else {
    imgPath = VirtualGenCounselImg
  }

  return (
    <Outer>
      <Title>{props.title}</Title>
      <Line height="3px" backgroundColor="#FC5B00" />
      <br />
      <br />
      <SpecialtyContent isDesktop={isDesktop}>
        <Image>
          <img src={imgPath} alt="img" style={{}}/>
        </Image>
        <Description isDesktop={isDesktop}>
          { props.description }
          <br />
          <AreaOfPracticeContent id={props.id} />
        </Description>
      </SpecialtyContent>
    </Outer>
  );
}

export default SpecialtyInfoComponent;
