import React, { Component } from 'react';
import styled from "styled-components";

import Navbar from '../components/Navbar/Navbar';
import Topbar from '../components/Topbar/Topbar';
import BottomSection from '../components/BottomSection/BottomSection.js';

const Message = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  height: 200px;
  background-color: white;
  flex-direction: column;
  width: 100%;
`;

class ContactThankYou extends Component {
    componentDidMount(){
      document.title = "Thank you | Dayna J. Pineda Law"
    }
    render() {
      return (
        <>
          <Topbar />
          <Navbar />
          <Message>
            Thank you for your inquiry! We will be in touch soon.
          </Message>
          <BottomSection />
        </>
      );
    }
}

export default ContactThankYou;
