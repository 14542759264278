export const AREAS_OF_PRACTICE = [
  {
    name: "Business",
    title: "Business Law",
    id: "business",
    img: "business.jpeg",
    desc: `Clients of all sizes and industry sectors ranging from small technology start-ups to
Fortune 500 companies have turned to us to provide legal and strategic advice. We
offer a wide variety of high quality, transactional services that are custom-tailored
to meet your needs in a cost-effective manner.`
  },
  {
    name: "Intellectual Property",
    title: "Intellectual Property Law",
    id: "intellectual-property",
    img: "intellectual-property.jpg",
    desc: `If your business involves technology, you have found the right law firm. We have
extensive experience representing biopharmaceutical, software, hardware,
telecommunications and other technology businesses of all sizes in transactional
matters. We understand that technology companies have unique challenges and we
aim to provide the highest quality of legal service through our in-depth
understanding of your technology, legal needs and strategic objectives.`
  },
  {
    name: "Virtual General Counsel",
    title: "Virtual General Counsel",
    id: "virtual-general-counsel",
    img: "virtual-general-counsel.jpg",
    desc: `Do you own or operate a small to medium-sized business with occasional legal
needs and want the advantage of assistance by an in-house general counsel without
the high cost of employing a full-time professional? Do you out-source your legal
needs to more than one attorney specialist? If so, our virtual general counsel
services may be right for you. We can design a custom-tailored, cost-effective
representation plan that works for you.`
  },
]
