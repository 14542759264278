import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import { Redirect } from 'react-router-dom'

import './ContactInfo.css';

const Outer = styled.div`
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  background-color: white;
`;

const Inner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ isDesktop }) => isDesktop ? 'row' : 'column'};
  justify-content: space-around;
  align-items: center;
  text-align: ${({ isDesktop }) => isDesktop ? 'left' : 'center'};
`;

const Contact = styled.div`
  width: ${({ isDesktop }) => isDesktop ? '600px' : '90%'};
`;

const SingleLineTextInput = styled.input`
  padding-left: 5px;
  font-size: 18px;
  height: 30px;
  width: 90%;
`;

const MultiLineTextInput = styled.textarea`
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  font-size: 18px;
  font-family: "Lato";
  height: 200px;
  width: 90%;
`;

function sendMessageToEmail(props, name, email, message) {
  emailjs.send("dpineda-email-service","template_tjnym6p",{
    from_name: name,
    from_email: email,
    message: message,
  }, 'Xnafm-ALAsHkN_WEd').then((result) => {
    console.log(result.text);
    window.location.replace("/contact-thank-you");
  }, (error) => {
    console.error(error.text);
    alert('Sorry we could not process your request. Please try again.')
  });
}

function ContactInfoComponent(props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth > 1000)
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Outer>
      <Inner isDesktop={isDesktop}>
        <Contact isDesktop={isDesktop}>
          <h1>Contact Us</h1>
          Send us your inquiries using the form below.
          <br /><br /><font color="red">* required fields</font>
          <h3>Your Name<font color="red"> *</font></h3>
          <SingleLineTextInput
            type="text"
            onChange={event => setName(event.target.value)} />
          <h3>Your Email<font color="red"> *</font></h3>
          <SingleLineTextInput
            type="email"
            onChange={event => setEmail(event.target.value)} />
          <h3>Your Message<font color="red"> *</font></h3>
          <MultiLineTextInput
            onChange={event => setMessage(event.target.value)} />
          <br /><br />
          <input
            type="submit"
            onClick={() => sendMessageToEmail(props, name, email, message)}
            value="Send Message" />
        </Contact>
      </Inner>
    </Outer>
  );
}

export default ContactInfoComponent;
