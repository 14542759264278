import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import sandiegoimg5 from '../../resources/sandiego5.jpeg';
import './HomeInfo.css';

import AreasOfPracticeInfo from '../AreasOfPracticeInfo/AreasOfPracticeInfo.js';
import ImageBackgroundBox from '../../containers/ImageBackgroundBox.js';
import Line from '../../parts/line.js';

const Headline = styled.div`
  position: absolute;
  height: 100px;
  width: ${({ isDesktop }) => isDesktop ? '500px' : '95%'};
  left: ${({ isDesktop }) => isDesktop ? '20px' : '0px'};
  bottom: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
  padding-left: ${({ isDesktop }) => isDesktop ? '20px' : '5%'};
  padding-top: 10px;
  padding-bottom: 10px;
`;

function HomeInfoComponent(props) {
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth > 1000)
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="homeInfo">
      <div style={{position: "relative"}}>
        <Headline isDesktop={isDesktop}>
          <div style={{fontSize: 30}}>25+ Years in San Diego</div>
          <div style={{color: "#FC5B00", fontSize: 30, fontStyle: "italic"}}>Experience. Value. Integrity.</div>
        </Headline>
        <ImageBackgroundBox height={600} imagePath={sandiegoimg5} />
      </div>
      <Line height="3px" backgroundColor="#FC5B00" />
      <AreasOfPracticeInfo backgroundColor="#203D67"/>
    </div>
  );
}

export default HomeInfoComponent;
