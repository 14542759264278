import React from 'react';

import './Navbar.css';

function NavBarListItems(props) {
  return (
    <ul style={{marginTop: "0px"}}>
      <li><a href="../" className={props.activeTab === "home" ? "active" : ""}>Home</a></li>
      <li><a href="../about" className={props.activeTab === "about" ? "active" : ""} >About</a></li>
      <li><a href="../values" className={props.activeTab === "values" ? "active" : ""} >Values</a></li>
      <li><a href="../areas-of-practice" className={props.activeTab === "areas-of-practice" ? "active" : ""} >Areas of Practice</a></li>
      <li><a href="../contact" className={props.activeTab === "contact" ? "active" : ""} >Contact</a></li>
    </ul>
  );
}

class Navbar extends React.Component {
  constructor() {
    super();
    this.state = {
      isDesktop: true,
    }
  }

  updateDimensions() {
    this.setState({width: window.innerWidth})
    if(window.innerWidth <= 1000) {
      this.setState({isDesktop: false});
    } else {
      this.setState({isDesktop: true});
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
  render() {
    var activeTab = this.props.activeTab
    let navbarlist = <NavBarListItems activeTab={activeTab} />
    if (!this.state.isDesktop) {
      navbarlist = null
    }
    return (
      <nav className= "navbar">
        {navbarlist}
      </nav>
    );
  }
}

export default Navbar;
