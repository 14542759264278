import React, { Component } from 'react';

class Line extends Component {
  render() {
    return (
      <div style={{height: this.props.height, width: "100%",
      backgroundColor: this.props.backgroundColor}}>
      </div>
    );
  }
}

export default Line;
