import React, { Component } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Topbar from '../components/Topbar/Topbar';
import ContactInfo from '../components/ContactInfo/ContactInfo';
import BottomSection from '../components/BottomSection/BottomSection.js';

class Contact extends Component {
  componentDidMount() {
    document.title = "Contact | Dayna J. Pineda Law"
  }
  render() {
      return (
        <div className="App">
        <Topbar />
        <Navbar activeTab="contact"/>
        <ContactInfo />
        <BottomSection />
        </div>
      );
  }
}

export default Contact;
